/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { selectRequestsByType } from 'ducks/requests/selectors'
import { camelize } from 'util/strings'
import { chunk } from 'util/arrays'
import { PRELOAD_CONVERSATIONS } from '../actionTypes'
import { preloadConversationFullGraphQlResponseSchema } from '../schema'
import { ticketPreloadConversationsQuery } from '../queries'
import { buildConversationRequestKey } from '../utils/request'

const BULK_UPDATE_KEY = 'tickets-preload'
const PRELOAD_BATCH_SIZE = 5

function groupByConversationId(items) {
  const grouped = []
  const conversationMap = new Map()

  items.forEach(item => {
    const {
      node: { conversationId },
    } = item

    if (!conversationMap.has(conversationId)) {
      // Create a new group for this conversationId
      const group = []
      grouped.push(group)
      conversationMap.set(conversationId, group)
    }

    // Add the item to the correct group
    conversationMap.get(conversationId).push(item)
  })

  return grouped
}

const transformAttachEventGroupIdToEvents = data => {
  if (data.eventGroups) {
    groupByConversationId(data.eventGroups.edges).forEach(
      eventGroupsByConversationId => {
        eventGroupsByConversationId.forEach((eg, index) => {
          eg.node.isFirstMessage = index === 0
          eg.node.isLastMessage = index === data.eventGroups.edges.length - 1
          eg.node.events.edges.forEach(e => {
            e.node.eventGroupId = eg.node.id
          })
        })
      }
    )
  }
  return data
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
const doPreloadTicketsBatch = ({
  ticketIds,
  options = {},
}) => async dispatch => {
  const concurrencyKey = `${BULK_UPDATE_KEY}`
  const conversationIds = ticketIds.map(tid => buildId('Conversation', tid))

  return dispatch(
    doGraphqlRequest(
      PRELOAD_CONVERSATIONS,
      ticketPreloadConversationsQuery(),
      {
        conversationIds,
        eventGroupsFilter: {
          conversationIds,
        },
        linkedResourcesFilter: {
          conversationIds,
        },
      },
      {
        ...options,
        app: true,
        concurrency: {
          key: concurrencyKey,
        },
        normalizationSchema: preloadConversationFullGraphQlResponseSchema,
        transformResponse: transformAttachEventGroupIdToEvents,
        meta: {
          channelType: MAILBOX_CHANNEL_TYPE,
          mergeEntities: true,
          updateSearches: true,
        },
        throwOnError: true,
      }
    )
  )
}

export const doPreloadTickets = ({
  ticketIds: inputTicketIds,
  options = {},
}) => async (dispatch, getState) => {
  const state = getState()

  const requestByType = selectRequestsByType(state)

  const excludingLoaded = inputTicketIds.filter(tid => {
    const requestKey = camelize(buildConversationRequestKey(tid))
    return requestByType[requestKey]?.loaded !== true
  })
  const batches = chunk(excludingLoaded, PRELOAD_BATCH_SIZE)

  return Promise.all(
    batches.map(batch =>
      dispatch(doPreloadTicketsBatch({ ticketIds: batch, options }))
    )
  )
}
