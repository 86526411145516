import {
  selectCurrentEntitiesById,
  selectCurrentEntityById,
  selectPendingEntityById,
  selectCurrentEntities,
  selectCurrentEntitiesDenormalizedByType,
} from 'ducks/entities/selectors'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { convertGidToRaw } from 'util/scatterSwap'
import {
  CHANGE_TYPE_CONTACT_OPENDED_MESSAGE,
  CHANGE_TYPE_FOLLOWER_ADDED,
  CHANGE_TYPE_FOLLOWER_REMOVED,
  CHANGE_TYPE_FORWARDED_MESSAGE,
  CHANGE_TYPE_EMAIL_MESSAGE,
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_REPLY_MESSAGE,
  CHANGE_TYPE_TWITTER_MESSAGE,
  CHANGE_TYPE_FACEBOOK_MESSAGE,
  CHANGE_TYPE_WIDGET_MESSAGE,
  CHANGE_TYPE_COMMENT_REACTED,
  CHANGE_TYPE_COMMENT_EDITED,
  CHANGE_TYPE_RATING,
} from '../constants'

export const selectCurrentConversationsById = state =>
  selectCurrentEntitiesById(state, 'conversation') || null

export const selectCurrentConversationById = (
  state,
  conversationId,
  isLoadedFn,
  shouldDenormalize
) =>
  selectCurrentEntityById(
    state,
    'conversation',
    conversationId,
    isLoadedFn,
    shouldDenormalize
  ) || null

export const selectPendingConversationById = (state, id) =>
  selectPendingEntityById(state, 'conversation', id) || null

export const selectCurrentConversations = state =>
  selectCurrentEntities(state, 'conversation') || emptyArr

export const selectConversationEventGroups = (
  state,
  shouldDenormalize = false
) => {
  const selectEntities = shouldDenormalize
    ? selectCurrentEntitiesDenormalizedByType
    : selectCurrentEntities
  return selectEntities(state, 'conversationEventGroup') || emptyArr
}

export const selectConversationEventGroupsById = state =>
  selectCurrentEntitiesById(state, 'conversationEventGroup') || emptyObj

export const selectConversationEventGroupById = (
  state,
  eventGroupId,
  isLoadedFn,
  shouldDenormalize
) =>
  selectCurrentEntityById(
    state,
    'conversationEventGroup',
    eventGroupId,
    isLoadedFn,
    shouldDenormalize
  ) || null

export const selectMessageById = (
  state,
  messageId,
  isLoadedFn,
  shouldDenormalize
) =>
  selectCurrentEntityById(
    state,
    'message',
    messageId,
    isLoadedFn,
    shouldDenormalize
  ) || null

export const selectMessagesById = state =>
  selectCurrentEntitiesById(state, 'message') || emptyObj

export const selectConversationEventById = (
  state,
  eventId,
  isLoadedFn,
  shouldDenormalize
) =>
  selectCurrentEntityById(
    state,
    'conversationEvent',
    eventId,
    isLoadedFn,
    shouldDenormalize
  ) || null

export const selectConversationsEvents = state =>
  selectCurrentEntities(state, 'conversationEvent') || emptyArr

export const selectConversationEventChangeIdToEventId = createSelector(
  selectConversationsEvents,
  events => {
    return events.reduce((byId, event) => {
      const { id: eventId } = event
      const changeId = event.change?.id
      if (!changeId) return byId
      // eslint-disable-next-line no-param-reassign
      byId[changeId] = eventId
      return byId
    }, {})
  }
)

export const selectConversationEventIdByChangeId = createCachedSelector(
  selectConversationEventChangeIdToEventId,
  (_state, changeId) => changeId,
  (byChangeIdToEventId, changeId) => {
    return byChangeIdToEventId[changeId] || null
  }
)((_state, changeId) => changeId || 'unknown')

export const selectConversationEvents = state =>
  selectCurrentEntities(state, 'conversationEvent') || emptyArr

export const selectConversationEventsById = state =>
  selectCurrentEntitiesById(state, 'conversationEvent') || emptyObj

export const selectConversationEventsByGroupId = createCachedSelector(
  selectConversationEvents,
  (_state, groupId) => groupId,
  (conversationEvents, groupId) =>
    conversationEvents
      .filter(ce => ce.eventGroupId === groupId)
      .sort((a, b) => {
        const aIsOId = a.id.startsWith('o:')
        const bIsOId = b.id.startsWith('o:')

        if (aIsOId && !bIsOId) return 1 // Move `a` to the end
        if (!aIsOId && bIsOId) return -1 // Move `b` to the end

        // Otherwise, sort by the converted ID values
        return convertGidToRaw(a.id) - convertGidToRaw(b.id)
      })
)((_state, groupId) => groupId || 'unknown')

export const selectConversationEventsForConversationId = createCachedSelector(
  selectConversationEvents,
  (_state, conversationId) => conversationId,
  (conversationEvents, conversationId) =>
    conversationEvents.filter(ce => ce.conversationId === conversationId)
)((_state, groupId) => groupId || 'unknown')

const RENDER_DISABLED_EVENTS = [
  CHANGE_TYPE_FOLLOWER_ADDED,
  CHANGE_TYPE_FOLLOWER_REMOVED,
  CHANGE_TYPE_FORWARDED_MESSAGE,
  CHANGE_TYPE_EMAIL_MESSAGE,
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_REPLY_MESSAGE,
  CHANGE_TYPE_TWITTER_MESSAGE,
  CHANGE_TYPE_FACEBOOK_MESSAGE,
  CHANGE_TYPE_WIDGET_MESSAGE,
  CHANGE_TYPE_COMMENT_REACTED,
  CHANGE_TYPE_COMMENT_EDITED,
  CHANGE_TYPE_RATING,
]

export const selectConversationEventsToRenderByGroupId = createCachedSelector(
  selectConversationEventsByGroupId,
  events =>
    events.filter(ce => {
      return !RENDER_DISABLED_EVENTS.includes(
        // eslint-disable-next-line no-underscore-dangle
        ce?.change?.schema || ce?.change?.__typename
      )
    })
)((_state, groupId) => groupId || 'unknown')

export const selectConversationHasReadReceiptByGroupId = createCachedSelector(
  selectConversationEventsByGroupId,
  events =>
    events.some(
      // eslint-disable-next-line no-underscore-dangle
      e => e.change?.__typename === CHANGE_TYPE_CONTACT_OPENDED_MESSAGE
    )
)((_state, groupId) => groupId || 'unknown')
