/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import debug from 'util/debug'
import { buildId, buildIdFromAny } from 'util/globalId'
import { uniq } from 'util/arrays'
import {
  isBridgeChannelType,
  isChatChannelType,
} from 'ducks/channels/channelTypes'
import { mapChannelTypeToGlobalIdType } from 'ducks/folders/utils'
import {
  LONGEST_UNANSWERED,
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import { RULE_STATE } from 'ducks/rules/constants'
import { camelize, capitalize } from 'util/strings'
import {
  GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX,
  MAILBOX_CUSTOM_FIELD_PREFIX,
} from 'ducks/crm/channels/constants'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { hash } from 'util/scatterSwap'
import { memoize } from 'util/memoization'

function mapBetweenField() {
  return (_, value, filter) => {
    const [after, before] = value.split('&')
    filter.afterCreatedAt = after
    filter.beforeCreatedAt = before
    return filter
  }
}

function mapToGidField(fieldName, gidType) {
  return (_, value, filter) => {
    filter[fieldName] = buildId(gidType, value)
    return filter
  }
}

function mapTeamToAssigned(_, value, filter) {
  if (value === 'none') {
    filter.assigned = {
      noTeam: true,
    }
  } else {
    filter.assigned = {
      team: value,
    }
  }
  return filter
}

function mapAgentToAssigned(_, value, filter) {
  if (value === 'none') {
    filter.assigned = {
      noAgent: true,
    }
  } else {
    filter.assigned = {
      agent: value,
    }
  }
  return filter
}

function mapIsToField(_, value, filter) {
  if (
    !filter.states &&
    [
      'unread',
      'open',
      'closed',
      'spam',
      'deleted',
      'trash',
      'snoozed',
    ].includes(value)
  ) {
    filter.states = []
  }
  switch (value) {
    case 'assigned':
      filter.assigned = {
        any: true,
      }
      break
    case 'unassigned':
      filter.assigned = {
        noTeam: true,
        noAgent: true,
      }
      break
    case 'starred':
      filter.starred = true
      break
    case 'unread':
      filter.states.push('UNREAD')
      break
    case 'open':
      filter.states.push('OPENED')
      break
    case 'closed':
      filter.states.push('CLOSED')
      break
    case 'spam':
      filter.states.push('SPAM')
      break
    case 'deleted':
      filter.states.push('TRASH')
      break
    case 'trash':
      filter.states.push('TRASH')
      break
    case 'snoozed':
      filter.states.push('SNOOZED')
      break
  }
  return filter
}

function mapMyToField(_, value, filter) {
  if (
    !filter.states &&
    ['unread', 'open', 'closed', 'deleted', 'snoozed'].includes(value)
  ) {
    filter.states = []
  }
  switch (value) {
    case 'unread':
      filter.states.push('UNREAD')
      filter.assignee = 'me'
      break
    case 'open':
      filter.states.push('OPENED')
      filter.assignee = 'me'
      break
    case 'drafts':
      filter.draftAuthor = 'me'
      break
    case 'snoozed':
      filter.states.push('SNOOZED')
      filter.assignee = 'me'
      break
    case 'closed':
      filter.states.push('CLOSED')
      filter.assignee = 'me'
      break
    case 'starred':
      filter.starred = true
      filter.assignee = 'me'
      break
    case 'deleted':
      filter.deleted = true
      filter.assignee = 'me'
      break
  }
  return filter
}

function mapDraftTypeField(_, value, filter) {
  switch (value) {
    case 'reply':
      filter.draftType = 'REPLY'
      break
    case 'note':
      filter.draftType = 'NOTE'
      break
  }
  return filter
}

function mapDraftToDraftAuthor(_, value, filter) {
  filter.draftAuthor = buildId('Agent', value)
  // TODO: Implement logic to convert username values to an author id
  return filter
}

function mapFieldToBoolean(fieldName) {
  return (_, value, filter) => {
    let booleanValue = !!value
    if ([0, '0', false, 'false', 'no'].includes(value)) {
      booleanValue = false
    } else if ([1, '1', true, 'true', 'yes'].includes(value)) {
      booleanValue = true
    }
    filter[fieldName] = booleanValue
    return filter
  }
}

const stripeStartEndQuote = inputString => {
  return inputString.startsWith('"') && inputString.endsWith('"')
    ? inputString.slice(1, -1)
    : inputString
}

function mapToField(fieldName, { decodeUri } = {}) {
  return (_, value, filter) => {
    filter[fieldName] = stripeStartEndQuote(
      decodeUri ? decodeURIComponent(value) : value
    )
    return filter
  }
}

export const mapToCustomField = customFieldIds => (_, value, filter) => {
  if (!filter.customFields) filter.customFields = []
  customFieldIds.forEach(customFieldId => {
    filter.customFields.push({
      customFieldId,
      values: [stripeStartEndQuote(value)],
    })
  })

  return filter
}

let QUERY_CONFIG = [
  {
    id: 'channelId',
    urlAliases: ['channel', 'mailbox', 'inbox'],
    keyToFilter: mapToGidField('channelId', 'Channel'),
    type: 'filter',
    order: 1,
    allowedValues: '*',
    allowMultiple: true,
  },
  {
    id: 'folderId',
    urlAliases: ['folder'],
    keyToFilter: mapToField('folderId'),
    type: 'filter',
    order: 200,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'unreadFolderId',
    urlAliases: ['folderunread'],
    keyToFilter: mapToField('unreadFolderId'),
    type: 'filter',
    order: 300,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'groupId',
    urlAliases: ['team', 'group', 'assigned_group'],
    keyToFilter: mapTeamToAssigned,
    type: 'filter',
    order: 400,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'agentId',
    urlAliases: ['assignee', 'agent', 'assigned'],
    keyToFilter: mapAgentToAssigned,
    type: 'filter',
    order: 500,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'is',
    urlAliases: ['is'],
    keyToFilter: mapIsToField,
    type: 'filter',
    order: 600,
    orderLookup: {
      assigned: 1,
      unassigned: 2,
      starred: 3,
      unread: 4,
      open: 5,
      closed: 6,
      spam: 7,
      snoozed: 8,
      trash: 9,
      rated: 10,
    },
    allowedValues: '*',
    allowMultiple: true,
  },
  {
    id: 'my',
    urlAliases: ['my'],
    keyToFilter: mapMyToField,
    type: 'filter',
    order: 600,
    orderLookup: {
      unread: 1,
      open: 2,
      drafts: 3,
      snoozed: 4,
      closed: 5,
      starred: 6,
      deleted: 7,
    },
    allowedValues: [
      'unread',
      'open',
      'drafts',
      'snoozed',
      'closed',
      'starred',
      'deleted',
    ],
    allowMultiple: false,
  },
  {
    id: 'draftAuthorId',
    urlAliases: ['draft'],
    keyToFilter: mapDraftToDraftAuthor,
    type: 'filter',
    order: 700,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'draftType',
    urlAliases: ['draftType', 'draft_type'],
    keyToFilter: mapDraftTypeField,
    type: 'filter',
    order: 701,
    allowedValues: ['reply', 'note'],
    allowMultiple: false,
  },
  {
    id: 'starred',
    urlAliases: ['starred'],
    keyToFilter: mapFieldToBoolean,
    type: 'filter',
    order: 800,
    allowedValues: ['all'],
    allowMultiple: false,
  },
  {
    id: 'rating',
    urlAliases: ['rating'],
    keyToFilter: mapFieldToBoolean,
    type: 'filter',
    order: 900,
    allowedValues: ['any', 'awesome', 'ok', 'bad'],
    allowMultiple: false,
  },
  {
    id: 'tagId',
    urlAliases: ['tagid', 'label'],
    keyToFilter: mapToField('tag'),
    type: 'filter',
    order: 1000,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'tag',
    urlAliases: ['tag'],
    keyToFilter: mapToField('tagName'),
    type: 'filter',
    order: 1001,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'deleted',
    urlAliases: ['deleted'],
    keyToFilter: mapFieldToBoolean,
    type: 'filter',
    order: 1100,
    allowedValues: ['all'],
    allowMultiple: false,
  },
  {
    id: 'mentions',
    urlAliases: ['mentions'],
    keyToFilter: mapToGidField('mentionedAgent', 'Agent'),
    type: 'filter',
    order: 1200,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'type',
    urlAliases: ['type'],
    keyToFilter: mapToField('type'),
    type: 'filter',
    order: 1300,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'orderBy',
    urlAliases: ['orderBy'],
    keyToFilter: mapToField('orderBy'),
    type: 'order',
    order: 1400,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'entityType',
    urlAliases: ['entityType'],
    keyToFilter: mapToField('entityType'),
    type: 'filter',
    order: 1500,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'cursor',
    urlAliases: ['cursor'],
    keyToFilter: mapToField('cursor'),
    type: 'filter',
    order: 1600,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'pageSize',
    urlAliases: ['pageSize'],
    keyToFilter: mapToField('pageSize'),
    type: 'filter',
    order: 1700,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'keywords',
    urlAliases: ['search', 'keywords'],
    keyToFilter: mapToField('keywords', { decodeUri: true }),
    type: 'filter',
    order: 1800,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'categoryId',
    urlAliases: ['category'],
    keyToFilter: mapToField('categoryId'),
    type: 'filter',
    order: 1900,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'state',
    urlAliases: ['state'],
    keyToFilter: mapIsToField,
    type: 'filter',
    order: 2000,
    allowedValues: ['closed', 'open', 'unread', 'snoozed', 'spam'],
    allowMultiple: false,
  },
  {
    id: 'folderState',
    urlAliases: ['folderState'],
    keyToFilter: mapToField('state'),
    type: 'filter',
    order: 2001,
    allowedValues: ['ACTIVE', 'INACTIVE'],
    allowMultiple: false,
  },
  {
    id: 'ruleState',
    urlAliases: ['ruleState'],
    keyToFilter: mapToField('state'),
    type: 'filter',
    order: 2002,
    allowedValues: Object.values(RULE_STATE),
    allowMultiple: false,
  },
  {
    id: 'name',
    urlAliases: ['name'],
    keyToFilter: mapToField('name'),
    type: 'filter',
    order: 2100,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'active',
    urlAliases: ['active'],
    keyToFilter: mapFieldToBoolean('active'),
    type: 'filter',
    order: 2200,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'scope',
    urlAliases: ['scope'],
    keyToFilter: mapToField('scope'),
    type: 'filter',
    order: 2300,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'from',
    urlAliases: ['from', 'customer'],
    keyToFilter: mapToField('contactEmail'),
    type: 'filter',
    order: 2600,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'before',
    urlAliases: ['before'],
    keyToFilter: mapToField('beforeCreatedAt'),
    type: 'filter',
    order: 2700,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'after',
    urlAliases: ['after'],
    keyToFilter: mapToField('afterCreatedAt'),
    type: 'filter',
    order: 2800,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'between',
    urlAliases: ['between'],
    keyToFilter: mapBetweenField(),
    type: 'filter',
    order: 2900,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'includeArchived',
    urlAliases: ['includeArchived'],
    keyToFilter: mapToField('includeArchived'),
    type: 'filter',
    order: 3000,
    allowedValues: '*',
    allowMultiple: false,
  },
  {
    id: 'includeAll',
    urlAliases: ['includeAll'],
    keyToFilter: mapToField('includeAll'),
    type: 'filter',
    order: 3001,
    allowedValues: '*',
    allowMultiple: false,
  },
]

let QUERY_CONFIG_INDEX = {}
// eslint-disable-next-line import/no-mutable-exports
export let QUERY_PRIMARY_ALIAS = {}

const rebuildConfig = () => {
  QUERY_CONFIG_INDEX = QUERY_CONFIG.reduce((index, config) => {
    index[config.id] = config
    config.urlAliases.forEach(alias => {
      index[alias] = config
    })
    return index
  }, {})

  QUERY_PRIMARY_ALIAS = QUERY_CONFIG.reduce((index, config) => {
    index[config.id] = config.urlAliases[0]
    return index
  }, {})
}

rebuildConfig()

// Kevin R: 2024-10-31
// This is abit of a code smell. We're essentially modifying a global variable which gets
// exported and used inside the application. We'll need to circle back at some point and look
// at perhaps moving this logic into a hook which can be tied to the redux state.
export const addQueryConfig = config => {
  const newConfig = QUERY_CONFIG.filter(c => c.id !== config.id)
  newConfig.push(config)
  QUERY_CONFIG = newConfig
  rebuildConfig()
}

function lookupOrder(part) {
  const [queryKey, queryValue] = part.split(':')
  if (![QUERY_PRIMARY_ALIAS.is, QUERY_PRIMARY_ALIAS.my].includes(queryKey)) {
    return QUERY_CONFIG_INDEX[queryKey].order
  }
  const {
    order: queryOrder,
    orderLookup: queryOrderLookup,
  } = QUERY_CONFIG_INDEX[queryKey]

  return queryOrder + (queryOrderLookup[queryValue] || 99)
}

// this function normalizes the query string coming from realtime
// it DOES NOT support quoted strings, only ids
export function normalizeSearchQueryId(queryId) {
  // when there is nothing to re-sort, short circuit
  if (!queryId.match(/\s/)) return queryId

  const parts = extractQueryParts(queryId)
  const sorted = parts.sort((a, b) => {
    const orderA = lookupOrder(a)
    const orderB = lookupOrder(b)
    return orderA > orderB ? 1 : -1
  })

  return sorted.join(' ')
}

function hackRoomFilter(filter) {
  // Currently room filters only support state and folderId
  // They also dont "correctly" mimic the ConversationFilter, so
  // we need to do some hack.
  const roomFilter = {
    channelType: filter.type ? filter.type.toUpperCase() : null,
  }
  if (filter.channelId) roomFilter.channelId = filter.channelId
  if (filter.folderId) roomFilter.folderId = filter.folderId

  return roomFilter
}

function applyTypeFilterTransformations(filter) {
  const { type, entityType } = filter
  // The concept of a "RoomFilter vs ConversationFilter shouldnt exists"
  // Once we normalize the api, we need to get rid of these hacks
  if (isChatChannelType(type) && !entityType) return hackRoomFilter(filter)
  delete filter.type
  return filter
}

export function constructGraphQLFilterObject(queryId, state) {
  if (!queryId) return null

  const queryParts = extractQueryParts(queryId)
  const data = applyTypeFilterTransformations(
    queryParts.reduce((filter, queryPart) => {
      const [key, value] = queryPart.split(':')
      const queryConfig = QUERY_CONFIG_INDEX[key]
      if (!queryConfig) {
        debug(
          `CRITICAL: Unable to map query part to graphql filter input: ${queryPart}`
        )
      }
      if (queryConfig && queryConfig.type === 'filter') {
        queryConfig.keyToFilter(key, value, filter, state)
      }
      return filter
    }, {})
  )
  return data
}

const API_MAP = {
  [NEWEST]: { field: 'UPDATED_AT', direction: 'DESC' },
  [OLDEST]: { field: 'UPDATED_AT', direction: 'ASC' },
  [NEWEST_BY_COLLABORATOR]: {
    field: 'LATEST_COLLABORATOR_COMMENT_AT',
    direction: 'DESC',
  },
  [OLDEST_BY_COLLABORATOR]: {
    field: 'LATEST_COLLABORATOR_COMMENT_AT',
    direction: 'ASC',
  },
  [LONGEST_UNANSWERED]: {
    field: 'LAST_UNANSWERED_USER_MESSAGE_AT',
    direction: 'ASC',
  },
  [NEWEST_BY_SPAM]: { field: 'STATE_CHANGED_AT', direction: 'DESC' },
  [NEWEST_BY_CLOSED]: { field: 'STATE_CHANGED_AT', direction: 'DESC' },
  [NEWEST_BY_DELETED]: { field: 'DELETED_AT', direction: 'DESC' },
}

export const queryArraysToCsv = obj => {
  return Object.keys(obj).reduce((query, key) => {
    const queryValue = obj[key]

    query[key] = Array.isArray(queryValue) ? queryValue.join(',') : queryValue
    return query
  }, {})
}

export const queryIdToQuery = memoize(
  (queryId, options = {}) => {
    const { targetId } = options
    if (!queryId) return null
    const targetPart = targetId ? `${targetId}-` : ''

    const query = {}
    const queryParts = extractQueryParts(queryId)
    queryParts.forEach(queryPart => {
      const [rawKey, value] = queryPart.split(':')
      const key = parseKey(rawKey, options)
      if (!QUERY_CONFIG_INDEX[key]) {
        debug(`Search query config not found for [${key}]`)
        return
      }
      const config = QUERY_CONFIG_INDEX[key]
      const mappedKey = config.urlAliases[0]
      const fullQueryKey = `${targetPart}${mappedKey}`
      const part = casePart(mappedKey, value, options)
      if (config.allowMultiple) {
        if (!query[fullQueryKey]) query[fullQueryKey] = []
        if (part) query[fullQueryKey].push(part)
      } else {
        query[fullQueryKey] = part
      }
    })
    if (options.csvArrays) {
      return queryArraysToCsv(query)
    }

    return query
  },
  {
    strategy: 'variadic',
  }
)

export function constructGraphQLOrderByObjectFromOrderBy(orderBy) {
  if (!orderBy) return null
  if (API_MAP[orderBy]) return API_MAP[orderBy]
  let orderField = null
  let orderDirection = 'ASC'
  ;['ASC', 'DESC'].forEach(direction => {
    const directionRegex = new RegExp(`(?<orderBy>.*)_${direction}$`)
    const match = orderBy.match(directionRegex)
    if (match) {
      orderField = match.groups.orderBy
      orderDirection = direction
    }
  })
  if (!orderField) return null
  return { field: orderField, direction: orderDirection }
}

export function constructGraphQLOrderByObject(queryId) {
  const { orderBy } = queryIdToQuery(queryId) || {}
  return constructGraphQLOrderByObjectFromOrderBy(orderBy)
}

export function constructApiV1SortBy(queryId) {
  const { orderBy } = queryIdToQuery(queryId) || {}
  return orderBy
}

function parseKey(key, { targetId }) {
  if (key === undefined) return undefined
  if (!targetId) return key
  const targetPart = `${targetId}-`
  if (!key.startsWith(targetPart)) return undefined
  return key.substr(targetPart.length)
}

export function filterQueryByTargetId(targetId, query, options) {
  const normalizedOptions = { ...options, targetId }
  return Object.keys(query).reduce((tQuery, rawKey) => {
    const key = parseKey(rawKey, normalizedOptions)
    if (key) {
      tQuery[key] = casePart(key, query[rawKey], normalizedOptions)
    }
    return tQuery
  }, {})
}

export function isLegacyQuery(query) {
  return Object.keys(query).some(q => q.includes(':'))
}

export const convertLegacyFormat = legacyQueryId => {
  return legacyQueryId
    .replace(/tag:/g, 'tagid:tag_')
    .replace(/inbox:/g, 'channel:ch_')
    .replace(/mentions:/g, 'mentions:ag_')
    .replace(/group:/g, 'team:')
    .replace(/folder:/g, 'folder:fol_')
}

export const queryStringToQueryId = memoize(
  (query = {}, options = {}) => {
    const { targetId } = options
    const queryParts = []
    query = !targetId ? query : filterQueryByTargetId(targetId, query, options)
    Object.keys(QUERY_CONFIG_INDEX).forEach(key => {
      if (query[key] === undefined) return
      const config = QUERY_CONFIG_INDEX[key]
      const primaryAlias = config.urlAliases[0]
      if (Array.isArray(query[key])) {
        if (key !== QUERY_PRIMARY_ALIAS.keywords) {
          query[key].forEach(value => {
            queryParts.push(`${primaryAlias}:${casePart(key, value, options)}`)
          })
        }
      } else {
        queryParts.push(`${primaryAlias}:${casePart(key, query[key], options)}`)
      }
    })
    return normalizeSearchQueryId(queryParts.join(' '))
  },
  {
    strategy: 'variadic',
  }
)

function casePart(
  key,
  value,
  {
    parseToIntKeys = [],
    decodeUriKeys = [],
    encodeUriKeys = [],
    parseNull = false,
  } = {}
) {
  if (['pageSize'].includes(key)) {
    return parseInt(value, 10)
  }
  if (parseToIntKeys.includes(key)) {
    return parseInt(value, 10)
  }
  if (decodeUriKeys.includes(key)) {
    return decodeURIComponent(value)
  }
  if (encodeUriKeys.includes(key)) {
    return encodeURIComponent(value)
  }
  if (parseNull && value === 'null') {
    return null
  }
  return value
}

// Handles splitting edge case queryIds like the following
// Input: state:open search: yes this does work starred:false search2:hur hur hur
// Output: ["state:open","search: yes this does work","starred:false","search2:hur hur hur"]
export function extractQueryParts(inputQueryId) {
  if (!inputQueryId) return null
  const matches = inputQueryId
    .trim()
    .match(/(?:[^\s"]+|(?:")[^"]*(?:"|$))+|(\s(?=\s|$))|(^\s+)/g)
  const r = matches && matches.map(x => (x.match(/^\s+$/) ? '' : x))
  const partsLookup = r.reduce((parts, queryPart) => {
    let [key, value] = queryPart.split(':')
    if (!value && !QUERY_CONFIG_INDEX.search.urlAliases.includes(key)) {
      // eslint-disable-next-line no-const-assign
      value = key
      // eslint-disable-next-line no-const-assign
      key = QUERY_CONFIG_INDEX.search.urlAliases[0]
    } else if (!QUERY_CONFIG_INDEX[key]) {
      value = `${key} ${value}`
      key = QUERY_CONFIG_INDEX.search.urlAliases[0]
    }
    if (!parts[key]) parts[key] = []
    parts[key].push(value)
    return parts
  }, {})

  return uniq(
    Object.keys(partsLookup).reduce((queryParts, queryKey) => {
      if (queryKey === QUERY_CONFIG_INDEX.search.urlAliases[0]) {
        queryParts.push(`${queryKey}:${partsLookup[queryKey].join(' ')}`)
      } else {
        partsLookup[queryKey].forEach(value => {
          queryParts.push(`${queryKey}:${value}`)
        })
      }
      return queryParts
    }, [])
  )
}

export function targetQuery(targetId, query) {
  return Object.keys(query).reduce((tQuery, key) => {
    tQuery[`${targetId}-${key}`] = query[key]
    return tQuery
  }, {})
}

export function clearTargetQuery(targetId, query) {
  return Object.keys(query).reduce((tQuery, key) => {
    if (!key.startsWith(targetId)) {
      tQuery[key] = query[key]
    }
    return tQuery
  }, {})
}

// Adds the currentUser id to any query parts that requert context.
// Currently this is only folders, but in future we might have additional types
// Example
// queryId = channel:ch_100 folder:fol_100 type:widget
// currentUserId = 2000
// return = channel:ch_100 folder:fol_100[2000] type:widget
export function addContextToQueryId(queryId, currentUserId) {
  return queryId.replace(/(folder:.+?)(\s|$)/, `$1[ag_${currentUserId}]$2`)
}

export function removeContextFromQueryId(queryId) {
  return queryId.replace(/(\[.*?\])/g, '')
}

export function removeKeysFromQueryId(keys, queryId) {
  if (!queryId) return ''

  return extractQueryParts(queryId)
    .filter(part => !keys.some(key => part.startsWith(`${key}:`)))
    .join(' ')
}

export function isForCurrentUser(queryId, currentUserId) {
  // If the query id doesnt have context, then it'll always be for the
  // current user
  if (!queryId.includes('[ag_')) return true
  // If the query does contain context, then make sure its for the current user
  return queryId.includes(`[ag_${currentUserId}]`)
}

export function constructFolderItemQueryId({
  channel,
  folder,
  channelType: pageChannelType,
  orderBy,
  assignee,
  tag,
  tagid,
  state,
}) {
  const { id: rawChannelId, channelType } = channel || {}
  const collectionQueryId = [
    `${QUERY_PRIMARY_ALIAS.type}:${channelType || pageChannelType}`,
  ]
  if (rawChannelId) {
    const channelId = buildId(
      mapChannelTypeToGlobalIdType(channelType || pageChannelType),
      rawChannelId
    )
    collectionQueryId.unshift(`${QUERY_PRIMARY_ALIAS.channelId}:${channelId}`)
  }

  if (folder) {
    const { queryId } = folder
    collectionQueryId.push(queryId)
  }

  if (orderBy) {
    collectionQueryId.push(`${QUERY_PRIMARY_ALIAS.orderBy}:${orderBy}`)
  }

  if (assignee) {
    const { gid } = assignee
    collectionQueryId.push(`${QUERY_PRIMARY_ALIAS.agentId}:${gid}`)
  }

  if (tag) {
    const { name } = tag
    collectionQueryId.push(`${QUERY_PRIMARY_ALIAS.tag}:${name}`)
  }

  if (tagid) {
    const { gid } = tagid
    collectionQueryId.push(`${QUERY_PRIMARY_ALIAS.tagId}:${gid}`)
  }
  if (state) {
    collectionQueryId.push(`${QUERY_PRIMARY_ALIAS.is}:${state}`)
  }

  return normalizeSearchQueryId(collectionQueryId.join(' '))
}

export function defaultFolderItemQueryId({
  channels,
  folders,
  prefersAllMailboxesSectionVisible,
  prefersUnifiedInbox,
  pageChannelType,
  orderBy,
}) {
  // Future dev, make sure you test the following cases to make sure the code is working
  // 1. Only 1 widget with prefersUnifiedInbox enabled --> Only all widgets displayed in expanded state
  // 2. Only 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is enabled --> Only display the widget in expanded state (All widgets not shown)
  // 3. Only 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is disabled --> Only display the widget in expanded state
  // 4. More than 1 widget with prefersUnifiedInbox enabled --> Only all widgets display in expanded state
  // 5. More than 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is enabled --> Display All widgets and each seperate widget with all widgets in expanded state
  // 6. More than 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is disabled --> Only display the widget with first widget in expanded state

  // Note channel can be either a mailbox of a widget. Mailboxes do not have the hasAccess field, but only
  // available mailboxes are passed to this function
  const channelsWithAccess = channels.filter(w => w.hasAccess !== false)
  if (channelsWithAccess.length === 0 || folders.length === 0) return null
  const hasOnlyOneChannel =
    channelsWithAccess.length === 1 && !prefersUnifiedInbox
  const requireChannel = !(
    prefersAllMailboxesSectionVisible || prefersUnifiedInbox
  )
  const channel =
    channelsWithAccess.length > 0 && (hasOnlyOneChannel || requireChannel)
      ? channelsWithAccess[0]
      : null

  const channelType = channel?.channelType || pageChannelType

  const allowedFolders = folders.filter(
    ({ name }) =>
      !['New messages', 'Ending soon'].includes(name) ||
      isBridgeChannelType(channelType)
  )
  return constructFolderItemQueryId({
    channel,
    folder: allowedFolders[0],
    channelType,
    orderBy,
  })
}

export function getAddedConversationIds(searches) {
  let conversationIds = []
  Object.keys(searches).forEach(rawSearchDiffQueryId => {
    const { plus } = searches[rawSearchDiffQueryId]
    conversationIds = conversationIds.concat(plus)
  })
  return uniq(conversationIds)
}

export const isQueryIdValid = queryId => {
  const queryObject = queryIdToQuery(queryId) || {}
  return Object.keys(queryObject).reduce((isValid, key) => {
    if (Object.keys(QUERY_CONFIG_INDEX).indexOf(key) < 0) return false // Invalid key
    const validValues = QUERY_CONFIG_INDEX[key].allowedValues
    const values = queryObject[key]
    const areValuesValid =
      [values].reduce((isValueValid, value) => {
        if (validValues.indexOf(value) < 0) return false
        if (value === '') return false
        return isValueValid
      }, true) && values.length > 0
    if (validValues !== '*' && !areValuesValid) return false // Invalid values
    return isValid
  }, true)
}

export const toFilterQueryId = queryId =>
  removeKeysFromQueryId(['orderBy', 'cursor', 'type'], queryId)

export const toBaseQueryId = queryId =>
  removeKeysFromQueryId(['orderBy', 'cursor'], queryId)

export const customFieldKeyToSearchKey = key => {
  return `#${capitalize(
    camelize(
      key
        .replace(GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX, '')
        .replace(new RegExp(`^${MAILBOX_CUSTOM_FIELD_PREFIX}\\d+_`), '')
    )
  )}`
}

export const convertRealtimeSearchesToSearches = (ticketId, ticketSearches) => {
  if (!ticketSearches) return null

  return Object.keys(ticketSearches).reduce((searches, inputQueryId) => {
    const queryObject = { ...queryIdToQuery(inputQueryId) }
    if (!queryObject) return null
    queryObject.type = MAILBOX_CHANNEL_TYPE
    if (queryObject.channel) {
      queryObject.channel = queryObject.channel.map(cid =>
        buildIdFromAny('Channel', cid)
      )
    }
    if (queryObject.folder) {
      queryObject.folder = buildIdFromAny('Folder', queryObject.folder)
    }
    if (queryObject.tag) {
      queryObject.tagid = buildId('Tag', hash(queryObject.tag))
      delete queryObject.tag
    }
    if (
      queryObject.assignee &&
      !['assigned', 'unassigned'].includes(queryObject.assignee)
    ) {
      queryObject.assignee = buildId('Agent', queryObject.assignee)
    }
    const queryId = queryStringToQueryId(queryObject)
    searches[queryId] = {
      plus: ticketSearches[inputQueryId] > 0 ? [ticketId] : [],
      minus: ticketSearches[inputQueryId] < 0 ? [ticketId] : [],
      equal: [],
    }
    return searches
  }, {})
}
