import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import {
  selectSearchNextEntityIdByQueryIdAndEntityId,
  selectSearchHasMoreByQueryId,
  selectSearchIsLoadingMoreByQueryId,
  selectSearchEntityIdsByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { doOpenTicketPage } from 'actions/pages'
import { selectAutoAdvanceToNextTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoAdvanceToNextTicketInList'
import { selectFolderRouteAction } from '../selectors/selectFolderRouteAction'
import { doOpenNextTicketPage } from '../actions/doOpenNextTicketPage'
import { doFetchTickets } from '../actions'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

// Ensure the logic in here matches the logic in selectWillAutoRedirectForTicketId
export const doAutoRedirect = (
  ticketId,
  inputNextTicketId,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  const forced = options.forced || false
  const queryId = selectCurrentQueryId(state)
  const classicView = selectPrefersClassicView(state)
  const shouldOpenFolder = selectPrefersOpenFolderOnTicketSave(state)
  const linkToFolder = selectFolderRouteAction(state)
  const nextTicketId =
    inputNextTicketId ||
    selectSearchNextEntityIdByQueryIdAndEntityId(state, queryId, ticketId)
  const hasMore = selectSearchHasMoreByQueryId(state, queryId)
  const isLoadingMore = selectSearchIsLoadingMoreByQueryId(state, queryId)
  const shouldAdvance = selectAutoAdvanceToNextTicketInList(state)
  const currentTicketId = selectCurrentTicketId(state)
  const currentTicketIds = selectSearchEntityIdsByQueryId(state, queryId)

  const isStillInCurrentQueryList = currentTicketIds.includes(ticketId)
  if (ticketId && currentTicketId !== ticketId && forced) {
    dispatch(doOpenTicketPage(ticketId, { preserveQuery: true }))
  } else if (shouldAdvance || forced) {
    if (nextTicketId) {
      // The rule here is that if the action I've taken causes the conversation to no longer
      // be in the conversation list im in, then I should move to the next conversation.
      // so for example, leaving a note doesnt change status so it wont move out of the
      // current folder. However leaving a note & closing would move it out of the current
      // folder and would mean the ticket should auto advance.
      if (
        (!isStillInCurrentQueryList && ticketId === currentTicketId) ||
        forced
      ) {
        dispatch(doOpenTicketPage(nextTicketId, { preserveQuery: true }))
      }
    } else if (hasMore && !isLoadingMore) {
      await dispatch(doFetchTickets())
      dispatch(doOpenNextTicketPage())
    }
  } else if ((classicView && shouldOpenFolder) || forced) {
    dispatch(linkToFolder)
  }
  return null
}
