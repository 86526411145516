import {
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import { SORT_ORDERS_FOR_FOLDER } from 'util/folders/SORT_ORDERS_FOR_FOLDER'
import { QUERY_PRIMARY_ALIAS, queryIdToQuery } from './query'

const isState = (query, state) => {
  return query?.is?.includes(state) || query?.state === state
}

const defaultFolderSortOrderKey = folder => {
  let sortOrder
  SORT_ORDERS_FOR_FOLDER.forEach((func, key) => {
    if (func(folder)) {
      sortOrder = key
      // eslint-disable-next-line no-useless-return
      return
    }
  })

  return sortOrder
}

export const buildSortContextParts = (
  queryId,
  sortByCollaboratorCommentAtEnabled,
  foldersById
) => {
  const query = queryIdToQuery(queryId) || {}

  const contextIdParts = []
  const hasDeletedQuery = isState(query, 'deleted') || isState(query, 'trash')
  const hasClosedQuery = isState(query, 'closed')
  const hasSpamQuery = isState(query, 'spam')

  if (hasDeletedQuery) {
    contextIdParts.push(NEWEST_BY_DELETED)
  }

  if (hasClosedQuery) {
    contextIdParts.push(NEWEST_BY_CLOSED)
  }

  if (hasSpamQuery) {
    contextIdParts.push(NEWEST_BY_SPAM)
  }

  if (query[QUERY_PRIMARY_ALIAS.folderId]) {
    const folder = foldersById[query[QUERY_PRIMARY_ALIAS.folderId]]
    const folderDefault = defaultFolderSortOrderKey(folder)
    if (folderDefault) contextIdParts.push(folderDefault)
  }

  if (sortByCollaboratorCommentAtEnabled) {
    contextIdParts.push(NEWEST_BY_COLLABORATOR)
    contextIdParts.push(OLDEST_BY_COLLABORATOR)
  } else {
    contextIdParts.push(NEWEST)
    contextIdParts.push(OLDEST)
  }
  return contextIdParts
}
