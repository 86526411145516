import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { CONVERSATION_EDIT_NOTE } from '../actionTypes'
import { updateConversationEditNoteMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationEditNoteGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { attachmentsToEmailAttachmentInput } from '../utils/payload'

export const doEditNote = (
  ticketId,
  messageId,
  body,
  inputAttachments = [],
  options = {}
) => async (dispatch, getState) => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const conversationId = buildId('Conversation', ticketId)

  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  if (!ticket || !body) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      note: {
        messageId,
        body,
        attachments: inputAttachments,
      },
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      CONVERSATION_EDIT_NOTE,
      updateConversationEditNoteMutation(),
      {
        conversationId,
        messageId,
        body,
        attachments,
        skipNotifications: !!options.skipNotifications,
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          normalizationSchema: conversationEditNoteGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationEditNote'
          ),
          optimist,
          moduleOptions: {
            snackbar: {
              enabled: true,
              started: {
                enabled: false,
              },
              success: {
                enabled: false,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't edit your note. Please try again in a moment.`,
              },
            },
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
