import { DEFAULT_MODULES } from 'ducks/requests/modules'

import { without, withPush } from 'util/arrays'

import { getRawId } from 'util/globalId'
import {
  entityAdditionalActionToActions,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { buildConversationOptimistDeleteOptions } from 'ducks/tickets/utils/optimistic'
import { selectByQueryId } from '../selectors'
import { MAX_REMEMBERED_SEARCHES } from '../constants'
import { mergeSearchChanges, searchUnload } from './action'
import { removeKeysFromQueryId } from './query'

const history = []
export const searchGarbageCollectModule = (phase, action, payload, options) => {
  if (phase !== 'SUCCESS') return action

  const currentQueryId = action?.request?.parameters?.queryId
  if (!currentQueryId) return action

  const cleanQueryId = removeKeysFromQueryId(['cursor'], currentQueryId)
  without(history, cleanQueryId)
  withPush(history, cleanQueryId)
  if (history.length <= MAX_REMEMBERED_SEARCHES) return action

  history.splice(0, history.length - MAX_REMEMBERED_SEARCHES)

  const getState = options.getState
  const state = getState()
  const byQueryId = selectByQueryId(state)

  const searchChanges = [action.searches ? { searches: action.searches } : null]
  const entityChanges = [action.entities ? { entities: action.entities } : null]

  const keepTicketIds =
    payload?.conversations?.nodes.map(node => getRawId(node.id)) || []
  const potentialGCTickets = []

  Object.keys(byQueryId).forEach(queryId => {
    if (!queryId.includes('type:mailbox') && !queryId.includes('type:widget'))
      return

    const query = byQueryId[queryId]
    if (!query.loaded) return

    if (history.includes(queryId)) {
      query.entityIds.forEach(entityId => {
        withPush(keepTicketIds, entityId)
      })
    } else {
      searchChanges.push(searchUnload(queryId))
      query.entityIds.forEach(entityId => {
        withPush(potentialGCTickets, entityId)
      })
    }
  })

  potentialGCTickets.forEach(ticketId => {
    if (!keepTicketIds.includes(ticketId)) {
      const { additionalActions } = buildConversationOptimistDeleteOptions(
        getState,
        ticketId
      )
      entityAdditionalActionToActions('STARTED', additionalActions).forEach(
        entityChange => {
          entityChanges.push(entityChange)
        }
      )
    }
  })

  return Object.assign(action, {
    ...mergeSearchChanges(searchChanges),
    ...mergeEntityChanges(entityChanges),
  })
}

// Its important that the garbage collection module is added to the end of the
// modules list as it relies on some of the other modules to have already run.
export const SEARCH_REQUEST_MODULES = [
  ...DEFAULT_MODULES,
  searchGarbageCollectModule,
]
