/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import debug from 'util/debug'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { camelize } from 'util/strings'
import produce from 'immer'
import { FETCH_CONVERSATION } from '../actionTypes'
import { conversationFullGraphQlResponseSchema } from '../schema'

import { buildConversationRequestKey } from '../utils/request'
import { ticketFullConversationQuery } from '../queries'
import {
  selectConversationEventGroupsById,
  selectMessagesById,
} from '../selectors'
import { CHANGE_TYPE_SUMMARY_MESSAGE } from '../constants'

const transformAttachEventGroupIdToEvents = data => {
  if (data.eventGroups) {
    data.eventGroups.edges.forEach((eg, index) => {
      eg.node.isFirstMessage = index === 0
      eg.node.isLastMessage = index === data.eventGroups.edges.length - 1
      eg.node.events.edges.forEach(e => {
        e.node.eventGroupId = eg.node.id
      })
    })
  }
  return data
}

const mergeEntities = getState => normalizedEntities => {
  const state = getState()
  const messagesById = selectMessagesById(state)
  const eventGroupsById = selectConversationEventGroupsById(state)

  return produce(normalizedEntities, draftTransformedEntities => {
    if (draftTransformedEntities.conversationEventGroup) {
      Object.keys(draftTransformedEntities.conversationEventGroup).forEach(
        eventGroupsId => {
          const newEventGroup =
            draftTransformedEntities.conversationEventGroup[eventGroupsId]
          const existingEventGroup = eventGroupsById[eventGroupsId]
          if (!existingEventGroup || existingEventGroup.collapsed) return
          newEventGroup.collapsed = false
        }
      )
    }

    if (draftTransformedEntities.message) {
      Object.keys(draftTransformedEntities.message).forEach(messageId => {
        const newMessage = draftTransformedEntities.message[messageId]
        const existingMessage = messagesById[messageId]
        if (
          !existingMessage ||
          existingMessage.__typename === CHANGE_TYPE_SUMMARY_MESSAGE
        )
          return
        newMessage.__typename = existingMessage.__typename
        // The api returns parts in chronological order, but we want to display them
        // in reverse chronological order. We do this by reversing the parts inside the
        // message processing strategy. This means that if we're adding the existing message
        // parts to the new message, we need to reverse the order, to ensure they get revesed
        // again when processing the message. A little convoluted, but it works.
        newMessage.parts = [...(existingMessage.parts || [])].reverse()
      })
    }
  })
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchTicket({
  conversationId: inputConversationId,
  channelType,
  options = {},
}) {
  return async (dispatch, getState) => {
    if (!inputConversationId || inputConversationId === 'new') return null
    const conversationId = buildId('Conversation', inputConversationId)
    if (channelType !== MAILBOX_CHANNEL_TYPE) {
      debug(
        `doFetchTicket currently only supports loading the ${MAILBOX_CHANNEL_TYPE} channel type`
      )
    }

    return dispatch(
      doGraphqlRequest(
        FETCH_CONVERSATION,
        ticketFullConversationQuery(),
        {
          conversationId,
          eventGroupsFilter: {
            conversationId,
          },
          linkedResourcesFilter: {
            conversationId,
          },
        },
        {
          ...options,
          app: true,
          normalizationSchema: conversationFullGraphQlResponseSchema,
          transformResponse: transformAttachEventGroupIdToEvents,
          transformEntities: mergeEntities(getState),
          meta: {
            channelType,
            requestKey: camelize(
              buildConversationRequestKey(inputConversationId)
            ),
            mergeEntities: true,
            updateSearches: true,
          },
          throwOnError: true,
        }
      )
    )
  }
}
