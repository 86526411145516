import { getRawId } from 'util/globalId'
import { selectDraftById } from 'ducks/drafts2/selectors'
import { startTimer } from './actions'

const extractUndoParams = response => {
  if (!response) return null
  let data = null
  ;[
    'conversationCreateEmail',
    'conversationForwardV2',
    'conversationReply',
  ].forEach(key => {
    if (response[key]) {
      data = {
        ticketId: response[key].conversation.id,
        changesetId: response[key].changesetId,
      }
    }
  })
  return data
}

export const withUndoSend = (draftId, action) => async (dispatch, getState) => {
  const draft = selectDraftById(getState(), draftId)

  const response = await dispatch(action)
  const params = extractUndoParams(response)
  if (!params) return response

  const { ticketId, changesetId } = params
  dispatch(startTimer({ ticketId: getRawId(ticketId), changesetId, draft }))
  return response
}
