// REPLACES src/constants/ticket_state_types.js
// These are the GQL values
export const UNREAD = 'UNREAD'
export const OPENED = 'OPENED'
export const CLOSED = 'CLOSED'
export const SNOOZED = 'SNOOZED'
export const DELETED = 'DELETED'
export const SPAM = 'SPAM'
export const TRASH = 'TRASH'

export const DELETE_MODE_SOFT = 'SOFT'
export const DELETE_MODE_HARD = 'HARD'

export const CHANGE_TYPE_AGENT_CHANGED = 'AgentChanged'
export const CHANGE_TYPE_CHANNEL_CHANGED = 'ChannelChanged'
export const CHANGE_TYPE_CONTACT_CHANGED = 'ContactChange'
export const CHANGE_TYPE_CONTACT_OPENDED_MESSAGE = 'ContactOpenedMessage'
export const CHANGE_TYPE_CONVERSATION_DELETED = 'ConversationDeleted'
export const CHANGE_TYPE_FOLLOWER_ADDED = 'FollowerAdded'
export const CHANGE_TYPE_FOLLOWER_REMOVED = 'FollowerRemoved'
export const CHANGE_TYPE_INTEGRATION_ADDED = 'IntegrationAdded'
export const CHANGE_TYPE_INTEGRATION_REMOVED = 'IntegrationRemoved'
export const CHANGE_TYPE_INTEGRATION_EXTERNAL_CHANGE =
  'IntegrationExternalChange'
export const CHANGE_TYPE_MESSAGE_MOVED = 'MessageMoved'
export const CHANGE_TYPE_CONVERSATION_MERGED = 'ConversationMerged'
export const CHANGE_TYPE_SHOPIFY_EXTERNAL_CHANGE = 'ShopifyExternalChange'
export const CHANGE_TYPE_SNOOZED = 'Snoozed'
export const CHANGE_TYPE_CONVERSATION_STARRED = 'ConversationStarred'
export const CHANGE_TYPE_SUBJECT_CHANGED = 'SubjectChanged'
export const CHANGE_TYPE_STATE_CHANGED = 'StateChanged'
export const CHANGE_TYPE_TAGGED = 'Tagged'
export const CHANGE_TYPE_UNTAGGED = 'Untagged'
export const CHANGE_TYPE_TEAM_CHANGED = 'TeamChanged'
export const CHANGE_TYPE_CONVERSATION_RESTORED = 'ConversationRestored'
export const CHANGE_TYPE_UNSNOOZED = 'Unsnoozed'
export const CHANGE_TYPE_CONVERSATION_UNSTARRED = 'ConversationUnstarred'

export const CHANGE_TYPE_FORWARDED_MESSAGE = 'ForwardedMessage'
export const CHANGE_TYPE_EMAIL_MESSAGE = 'EmailMessage'
export const CHANGE_TYPE_NOTE_MESSAGE = 'Note'
export const CHANGE_TYPE_REPLY_MESSAGE = 'Reply'
export const CHANGE_TYPE_TWITTER_MESSAGE = 'TwitterMessage'
export const CHANGE_TYPE_FACEBOOK_MESSAGE = 'FacebookMessage'
export const CHANGE_TYPE_WIDGET_MESSAGE = 'WidgetMessage'
export const CHANGE_TYPE_SUMMARY_MESSAGE = 'SummaryMessage'

export const CHANGE_TYPE_NOTE_DRAFT = 'NoteDraft'
export const CHANGE_TYPE_REPLY_DRAFT = 'ReplyDraft'
export const CHANGE_TYPE_TWITTER_DRAFT = 'TwitterDraft'
export const CHANGE_TYPE_NEW_WIDGET = 'NewWidget'
export const CHANGE_TYPE_RATING = 'Rating'
export const CHANGE_TYPE_COMMENT_DELETED = 'CommentDeleted'
export const CHANGE_TYPE_COMMENT_EDITED = 'CommentEdited'
export const CHANGE_TYPE_COMMENT_REACTED = 'CommentReacted'

// Note
// Change type CHANGE_TYPE_SUMMARY_MESSAGE is intentionally excluded here
// as its not considered a full loaded message, so a bunch of fields are
// not available. The summary is also usually just the plain text content
export const MESSAGE_TYPES = [
  CHANGE_TYPE_FORWARDED_MESSAGE,
  CHANGE_TYPE_EMAIL_MESSAGE,
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_REPLY_MESSAGE,
  CHANGE_TYPE_TWITTER_MESSAGE,
  CHANGE_TYPE_FACEBOOK_MESSAGE,
  CHANGE_TYPE_WIDGET_MESSAGE,
]

export const CONTACT_ROLE_CUSTOMER = 'CUSTOMER'
export const CONTACT_ROLE_COLLABORATOR = 'COLLABORATOR'

export const CONVERSATION_TYPE_WIDGET = 'WidgetConversation'
export const CONVERSATION_TYPE_FACEBOOK = 'FacebookConversation'
export const CONVERSATION_TYPE_TWITTER = 'TwitterConversation'
export const CONVERSATION_TYPE_CONVERSATION = 'Conversation'

export const NEW_CONVERSATION_ID = 'new'

export const CONVERSATION_SAVE_UNLOAD_MESSAGE = `Your conversation is being saved. If you close the window, you might lose your changes`

export const TRACK_TYPE_FOLLOW_TICKET_TOP_BAR = 'Inbox - Email Top Bar'
export const TRACK_TYPE_FOLLOW_TICKET_NOTE = 'Inbox - Email Note Form'
