import emailParser from 'email-addresses'
import { compact } from 'util/arrays'

// The backend seems to throw nil bombs when empty arrays are passed along which seems wrong.
// This will work around the issue for now, but we should really fix that up.
export const arrayOrNull = array => {
  if (!array) return null
  if (!Array.isArray(array)) return null
  if (array.length === 0) return null

  return array
}

export const emailToEmailRecipientInput = email => {
  const item = emailParser.parseOneAddress(email)
  if (!item) return null
  return { email: item.address, name: item.name }
}

export const emailArrayToEmailRecipientInputArray = arrayOfEmails => {
  if (!arrayOfEmails) return null
  if (!Array.isArray(arrayOfEmails)) return null

  return compact(arrayOfEmails.map(emailToEmailRecipientInput))
}

export const attachmentsToEmailAttachmentInput = attachments => {
  return (attachments || []).map(a => ({
    key: a.key,
    fileName: a.fileName,
    fileSize: a.fileSize.toString(),
    contentType: a.contentType,
  }))
}
