import { doDeleteDraftLocally } from 'ducks/drafts2/operations'
import { saveRequests } from 'ducks/drafts2/operations/saveRequests'
import { doUpdateEditorVisibility } from 'ducks/editor'
import { isPromise } from 'util/functions'
import { FAILED_SEND } from 'ducks/drafts2/constants'
import { selectDraftById } from 'ducks/drafts2/selectors'
import { selectSearchNextEntityIdByQueryIdAndEntityId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EVENT_GROUPS,
  FETCH_CONVERSATION_EVENTS_BY_GROUP_ID,
} from '../actionTypes'
import { doAutoRedirect } from '../actions/doAutoRedirect'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

export const buildConversationRequestKey = conversationId =>
  `${FETCH_CONVERSATION}_${conversationId}`

export const buildConversationEventGroupsRequestKey = conversationId =>
  `${FETCH_CONVERSATION_EVENT_GROUPS}_${conversationId}`

export const buildConversationEventsByGroupIdRequestKey = eventGroupId =>
  `${FETCH_CONVERSATION_EVENTS_BY_GROUP_ID}_${eventGroupId}`

const defaultExtractTicketId = ticketId => () => ticketId
export const withAutoRedirect = (ticketId, action, options = {}) => async (
  dispatch,
  getState
) => {
  const {
    moduleOptions: {
      autoRedirect: {
        enabled: attemptRedirect = true,
        redirectFirst,
        extractTicketId: inputExtractTicketId,
        // Ignore the default rule and force a redirection
        forced = false,
      } = {},
    } = {},
  } = options

  const extractTicketId =
    inputExtractTicketId || defaultExtractTicketId(ticketId)

  if (attemptRedirect) {
    if (redirectFirst) {
      await dispatch(doAutoRedirect(ticketId, null, { forced }))
      return dispatch(action)
    }

    // Its important to calculate the next ticket id before the action is taken because
    // the action can remove the ticket from the current ticket list meaning we cant determine
    // where in the ticket list this conversation was.
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const nextTicketId = selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      ticketId
    )

    const response = await dispatch(action)
    const currentTicketId = selectCurrentTicketId(getState())

    // Handle situations where the navigate to a new ticket before the action being taken
    // executes, then it should not auto redirect anymore
    if (currentTicketId === ticketId) {
      const extractedTicketId = extractTicketId(response)
      if (extractedTicketId)
        dispatch(doAutoRedirect(extractedTicketId, nextTicketId, { forced }))
    }
    return response
  }
  return dispatch(action)
}

export const onUpdateAttachEventGroupIdToEvents = mutationName => {
  return data => {
    if (data[mutationName].eventGroup) {
      const eventGroup = data[mutationName].eventGroup
      eventGroup.isLastMessage = true
      eventGroup.events.edges.forEach(e => {
        // eslint-disable-next-line no-param-reassign
        e.node.eventGroupId = eventGroup.id
      })
    }
    return data
  }
}

export const withBeforeSend = (ticketId, draftId, action) => async (
  dispatch,
  getState
) => {
  const draft = selectDraftById(getState(), draftId)

  dispatch(doDeleteDraftLocally(ticketId, draftId))
  // TODO: We can rather have the editor hide as part of the START operation
  // on the send
  dispatch(doUpdateEditorVisibility(false))
  const draftSavePromise = saveRequests[draftId]
  if (isPromise(draftSavePromise)) {
    await draftSavePromise
    delete saveRequests[draftId]
  }
  try {
    const response = await dispatch(action)
    return response
  } catch (e) {
    dispatch({
      type: FAILED_SEND,
      payload: { draftId, draft },
    })
    dispatch(doUpdateEditorVisibility(true))
    throw e
  }
}
