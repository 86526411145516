/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectWidgetsWithBridged } from 'ducks/widgets/selectors/widgets'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import metrics from 'util/metrics'
import { selectCurrentFolderById } from 'ducks/folders/selectors/folders'
import { selectIsOnSearchPage } from 'selectors/location'
import { isValidNumber } from 'actions/search/isValidNumber'
import { buildId } from 'util/globalId'
import { doOpenTicketPage } from 'actions/pages'
import {
  constructGraphQLFilterObject,
  constructGraphQLOrderByObjectFromOrderBy,
  queryIdToQuery,
} from '../utils/query'
import { SEARCH_CONVERSATIONS } from '../actionTypes'
import { conversationsGraphQlResponseSchema } from '../schema'
import { roomConversationsQuery, ticketConversationsQuery } from '../queries'
import { selectSearchIsLoadedByQueryId } from '../selectors'
import { SEARCH_REQUEST_MODULES } from '../utils/request'
import { selectDefaultSortOrderByQueryId } from '../selectors/selectDefaultSortOrderByQueryId'

function hackRoomToConversationResponse(channelType) {
  return data => {
    if (isChatChannelType(channelType)) {
      data.rooms.nodes.forEach(node => {
        node.internalId = node.id
        node.id = parseInt(node.number, 10)
        delete node.number
      })
      data.conversations = data.rooms
      delete data.rooms
    }
    return data
  }
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export const doFetchConversations = ({
  queryId,
  cursor,
  channelType,
  size = 20,
  options = {},
}) => async (dispatch, getState) => {
  const state = getState()
  const isOnSearchPage = selectIsOnSearchPage(state)
  const filter = constructGraphQLFilterObject(queryId, state) || {}
  const query = queryIdToQuery(queryId)
  const defaultOrderBy = selectDefaultSortOrderByQueryId(state, queryId)

  let orderBy = constructGraphQLOrderByObjectFromOrderBy(
    query?.orderBy || defaultOrderBy
  )

  const searchLoaded = selectSearchIsLoadedByQueryId(state, queryId)
  const isTicketIdSearch = filter.keywords && isValidNumber(filter.keywords)

  // In classic view you have the problem that when
  // 1. You've searched for a ticket by its id
  // 2. The system automatically opened the conversation for you
  // 3. Now you want to go back to the search results page.
  // The system will then automatically redirect back to the ticket page leaving you stuck
  // In order to address this we'll only allow the auto redirect to the ticket page
  // the first time the search is loaded.
  if (searchLoaded && isTicketIdSearch) return null

  // HACK: These should come from the backend API as the default sort order
  // for the filters. Incorporate together with filter permissions for chat.
  if (!orderBy && filter?.folderId && filter?.channelId) {
    const [, widgetId] = filter.channelId.split('_')
    const widget = selectWidgetsWithBridged(state).find(w => w.id === widgetId)
    if (widget) {
      const folder = selectCurrentFolderById(state, filter.folderId)
      if (['New messages', 'Ending soon'].includes(folder?.name)) {
        orderBy = { field: 'EXPIRES_AT', direction: 'ASC' }
      }
    }
  }

  metrics.startOrEnhanceTimer(`search-load-${queryId}`, {
    metric: 'search-load',
    logname: 'main',
    hot: false,
    queryId,
  })

  const fetchResponse = await dispatch(
    doGraphqlRequest(
      SEARCH_CONVERSATIONS,
      channelType === 'widget'
        ? roomConversationsQuery()
        : ticketConversationsQuery(),
      {
        filter,
        queryId,
        orderBy,
        cursor,
        size,
      },
      {
        ...options,
        modules: SEARCH_REQUEST_MODULES,
        app: true,
        transformResponse: hackRoomToConversationResponse(channelType),
        normalizationSchema:
          channelType === 'widget' ? null : conversationsGraphQlResponseSchema,
        meta: {
          channelType,
          mergeEntities: true,
        },
      }
    )
  )
  if (
    isOnSearchPage &&
    filter.keywords &&
    isValidNumber(filter.keywords) &&
    !!fetchResponse?.conversations?.nodes?.length
  ) {
    const conversationId = buildId('Conversation', filter.keywords)
    if (
      fetchResponse?.conversations?.nodes.some(c => c.id === conversationId)
    ) {
      dispatch(doOpenTicketPage(filter.keywords, { preserveQuery: true }))
    }
  }
  return fetchResponse
}
