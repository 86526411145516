import {
  conversation,
  conversationEvent,
  conversationEventGroup,
  conversationLinkedResource,
} from 'ducks/entities/schema'

export const conversationGraphQlResponseSchema = { node: conversation }

export const conversationFullGraphQlResponseSchema = {
  node: conversation,
  eventGroups: { edges: [{ node: conversationEventGroup }] },
  linkedResources: { edges: [{ node: conversationLinkedResource }] },
}

export const preloadConversationFullGraphQlResponseSchema = {
  nodes: [conversation],
  eventGroups: { edges: [{ node: conversationEventGroup }] },
  linkedResources: { edges: [{ node: conversationLinkedResource }] },
}

export const conversationEventGroupsGraphQlResponseSchema = {
  eventGroups: { edges: [{ node: conversationEventGroup }] },
  linkedResources: { edges: [{ node: conversationLinkedResource }] },
}

export const conversationEventsGraphQlResponseSchema = {
  events: { edges: [{ node: conversationEvent }] },
}

export const createConversationUpdateResponseSchema = mutationName => {
  return {
    [mutationName]: {
      conversation,
      eventGroup: conversationEventGroup,
    },
  }
}

export const conversationUpdateSubjectGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationSubject'
)
export const conversationAddTagsGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationTag'
)

export const conversationToggleNoteReactionGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationToggleNoteReaction'
)

export const conversationDeleteNoteGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationDeleteNote'
)

export const conversationRemoveTagsGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationUntag'
)

export const conversationStarGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationStar'
)

export const conversationUnstarGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationUnstar'
)

export const conversationStateUnreadGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationUnread'
)

export const conversationStateOpenGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationOpen'
)

export const conversationStateCloseGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationClose'
)

export const conversationStateSnoozeGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationSnooze'
)

export const conversationStateSpamGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationSpam'
)

export const conversationStateTrashGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationTrash'
)

export const conversationFollowGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationFollow'
)

export const conversationUnfollowGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationUnfollow'
)

export const conversationChangeChannelGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationChangeChannel'
)

export const conversationAssignGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationAssign'
)

export const conversationUnassignGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationUnassign'
)

export const conversationReplyGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationReply'
)

export const conversationAddNoteGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationAddNote'
)

export const conversationEditNoteGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationEditNote'
)

export const conversationCreateEmailGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationCreateEmail'
)

export const conversationLogGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationLog'
)

export const conversationForwardGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationForwardV2'
)

export const conversationLinkResourceGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationLinkResource'
)

export const conversationDeleteGraphQlResponseSchema = createConversationUpdateResponseSchema(
  'conversationDelete'
)

export const conversationMergeGraphQlResponseSchema = {
  conversationMerge: {
    target: conversation,
  },
}

export const conversationStateGraphQlResponseSchema = {
  ...conversationStateUnreadGraphQlResponseSchema,
  ...conversationStateOpenGraphQlResponseSchema,
  ...conversationStateCloseGraphQlResponseSchema,
  ...conversationStateSpamGraphQlResponseSchema,
  ...conversationStateTrashGraphQlResponseSchema,
}
