import {
  eventFragment,
  actorFragment,
  emailMessageFragment,
  widgetFragment,
  noteFragment,
  replyFragment,
  recipientFragment,
  forwardFragment,
  attachmentFragment,
  conversationFragment,
  widgetConversationFragment,
  authorFragment,
  customFieldValueFragment,
  allConversationFragments,
  eventGroupFragment,
  linkedResourceFragment,
  ruleFragment,
} from './fragments'

export const ticketEventsQuery = () => `
query TicketEvents($filter: EventsFilterInput!) {
  events(filter: $filter) {
    edges {
      node {
        ...eventFragment
      }
      __typename
    }
  }
}

${eventFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
${ruleFragment()}
`

export const ticketEventGroupsQuery = () => `
query TicketEventGroups($eventGroupsFilter: EventGroupsFilterInput!, $linkedResourcesFilter: LinkedResourcesFilterInput!) {
  eventGroups(filter: $eventGroupsFilter) {
    edges {
      node {
        ...eventGroupFragment
      }
    }
  }
  linkedResources(filter: $linkedResourcesFilter) {
    edges {
      node {
        ...linkedResourceFragment
      }
    }
  }
}

${eventFragment()}
${eventGroupFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
${ruleFragment()}
${linkedResourceFragment()}
`

export const ticketConversationQuery = () => `
query TicketConversationQuery($conversationId: ID!) {
  node(id: $conversationId) {
    ${allConversationFragments()}
  }
}

${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
`

export const ticketFullConversationQuery = () => `
query TicketConversationQuery($conversationId: ID!, $eventGroupsFilter: EventGroupsFilterInput!, $linkedResourcesFilter: LinkedResourcesFilterInput!) {
  node(id: $conversationId) {
    ${allConversationFragments()}
  }
  eventGroups(filter: $eventGroupsFilter) {
    edges {
      node {
        ...eventGroupFragment
      }
    }
  }
  linkedResources(filter: $linkedResourcesFilter) {
    edges {
      node {
        ...linkedResourceFragment
      }
    }
  }
}

${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
${eventFragment()}
${eventGroupFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
${linkedResourceFragment()}
${ruleFragment()}
`

export const ticketPreloadConversationsQuery = () => `
query TicketPreloadConversationsQuery($conversationIds: [ID!]!, $eventGroupsFilter: EventGroupsFilterInput!, $linkedResourcesFilter: LinkedResourcesFilterInput!) {
  nodes(ids: $conversationIds) {
    ${allConversationFragments()}
  }
  eventGroups(filter: $eventGroupsFilter) {
    edges {
      node {
        ...eventGroupFragment
      }
    }
  }
  linkedResources(filter: $linkedResourcesFilter) {
    edges {
      node {
        ...linkedResourceFragment
      }
    }
  }
}

${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
${eventFragment()}
${eventGroupFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
${linkedResourceFragment()}
${ruleFragment()}
`

export const ticketRawEmailQuery = () => `
query TicketRawEmail($rawEmailId: ID!) {
  node(id: $rawEmailId) {
    ... on RawEmail {
      id
      headers {
        name
        value
      }
      bodyPlainText
      body
      createdAt
    }
  }
}
`
