import { createActionTypeReducer } from 'util/reducers'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { queryStringToQueryId } from 'ducks/searches/utils/query'
import {
  SEARCH_PAGE,
  TICKET_COMMENT_PAGE,
  TICKET_PAGE,
  TICKETS_PAGE,
  MAILBOX_PERSIST_KEY,
  NEW_CONVERSATION_PAGE,
  LOG_CONVERSATION_PAGE,
  MAIN_PAGE,
} from 'constants/pages'
import storage from 'util/storage'
import {
  MAILBOX_CHANNEL_TYPE,
  SOCIAL_CHANNEL_TYPE,
  WIDGET_CHANNEL_TYPE,
} from 'ducks/folders/constants'

const currentQueryIdFromQueryReducer = (persistKey, fallbackType) => (
  _,
  action
) => {
  // https://github.com/faceyspacey/redux-first-router/blob/master/docs/query-strings.md#where-query-lives-on-your-actions
  const {
    meta: { location: { current: { query: inputQuery = {} } = {} } = {} } = {},
  } =
    action || {}

  const query = !inputQuery.type
    ? { ...inputQuery, type: fallbackType }
    : inputQuery
  const queryId = queryStringToQueryId(query)

  if (persistKey && queryId) {
    storage.set(persistKey, queryId)
  }

  return queryId
}

export const currentQueryId = createActionTypeReducer(
  {
    [CHAT_PAGE]: currentQueryIdFromQueryReducer(null, WIDGET_CHANNEL_TYPE),
    [SOCIAL_PAGE]: currentQueryIdFromQueryReducer(null, SOCIAL_CHANNEL_TYPE),
    [SEARCH_PAGE]: currentQueryIdFromQueryReducer(null, MAILBOX_CHANNEL_TYPE),
    [TICKETS_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY,
      MAILBOX_CHANNEL_TYPE
    ),
    [TICKET_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY,
      MAILBOX_CHANNEL_TYPE
    ),
    [TICKET_COMMENT_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY,
      MAILBOX_CHANNEL_TYPE
    ),
    [LOG_CONVERSATION_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY,
      MAILBOX_CHANNEL_TYPE
    ),
    [NEW_CONVERSATION_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY,
      MAILBOX_CHANNEL_TYPE
    ),
    [MAIN_PAGE]: currentQueryIdFromQueryReducer(null, MAILBOX_CHANNEL_TYPE),
  },
  null
)
