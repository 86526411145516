import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { CONVERSATION_ADD_NOTE } from '../actionTypes'
import { updateConversationAddNoteMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationAddNoteGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
  withBeforeSend,
} from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { attachmentsToEmailAttachmentInput } from '../utils/payload'

export const doAddNote = (
  ticketId,
  body,
  draftId,
  inputState,
  snoozeUntil,
  inputAgentId,
  inputTeamId,
  inputAttachments,
  options = {}
) => async (dispatch, getState) => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const conversationId = buildId('Conversation', ticketId)
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)

  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  const state = snoozeUntil ? SNOOZED : inputState
  if (!ticket || !body) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      state,
      snoozeUntil,
      agentId,
      teamId,
      note: {
        body,
        attachments: inputAttachments,
      },
    },
    options
  )
  return dispatch(
    withBeforeSend(
      ticketId,
      draftId,
      withAutoRedirect(
        ticketId,
        doGraphqlRequest(
          CONVERSATION_ADD_NOTE,
          updateConversationAddNoteMutation(),
          {
            conversationId,
            body,
            draftId,
            state,
            snoozeUntil,
            agentId,
            teamId,
            attachments,
            skipNotifications: !!options.skipNotifications,
          },
          mergePlain(
            {
              app: true,
              throwOnError: true,
              concurrency: {
                key: ticketId,
                message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
              },
              normalizationSchema: conversationAddNoteGraphQlResponseSchema,
              transformResponse: onUpdateAttachEventGroupIdToEvents(
                'conversationAddNote'
              ),
              optimist,
              moduleOptions: {
                snackbar: {
                  enabled: true,
                  started: {
                    enabled: false,
                  },
                  success: {
                    enabled: false,
                  },
                  failed: {
                    enabled: true,
                    message: `Oops, we couldn't add your note. Please try again in a moment.`,
                  },
                },
                entities: {
                  additionalActions,
                },
              },
              meta: {
                mergeEntities: true,
              },
            },
            options
          )
        ),
        options
      )
    )
  )
}
